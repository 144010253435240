@import "custom/button";
@import "custom/card";
@import "custom/calendar";
@import "custom/dashboard";
@import "custom/input";
@import "custom/loader";
@import "custom/modal";
@import "custom/positions";
@import "custom/sidebar";
@import "custom/table";
@import "custom/utils";

// Here you can add other styles
app-login {
  display: contents;
}

app-register {
  display: contents;
}

app-change-password {
  display: contents;
}

app-join {
  display: contents;
}

// ::placeholder {
//   color: rgba(90, 90, 90, .25);
// }

input {
  border: 1px solid var(--secondary) !important;
}

cui-breadcrumb {
  .breadcrumb {
    margin-bottom: 1rem;
  }
}

p-autocomplete {
  .ui-autocomplete {
    width: 100%;
  }
}

.auto-complete-group {
  display: -webkit-box;

  app-auto-complete {
    margin: 0;
    width: max-content;
    .ui-autocomplete {
      input {
        border-color: #a6a6a6;
        border-right: none;
      }
    }
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: min-content;
    margin: 0;
    border-color: #a6a6a6;
    border-left: none;
  }

}

pagination {
  ul {
    margin-bottom: 0;
  }
}

// Label position
.col-form-label {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .required-alert {
    color: rgba(221, 0, 0, .75);
    content: "*";
    margin-left: 5px;
  }

  &.small {
    font-size: 0.7rem !important;
  }
}

// Adjust the form group margin bottom
.form-group {
  margin-bottom: 4px;
}

// Show error indicator
// .col-form-label.required:after {
//   color: #d00;
//   content: "*";
//   position: absolute;
//   margin-left: 5px;
//   top:7px;
// }

form.has-error {
  .form-control.ng-invalid.ng-touched {
    border: 1px solid #f86c6b !important
  }
}

.click {
  cursor: pointer
}

span.link, a.link {
  color: #20a8d8;
  border-radius: 0;
  cursor: pointer;
}

.main {
  background-image: url("../assets/img/fundo.png");
  background-repeat: no-repeat;
  background-position: center;
  @include media-breakpoint-down(sm) {
    background-size: 80%;
  }
}

.modal-open {
  & > .dropdown {
    z-index: 1051 !important;
  }
}

.datepiker-today-class {
  background-color: rgba(141, 141, 170, 0.4);
}

.debug-alert-header-color {
  background-color: gold;
}

.qa-alert-header-color {
  background-color: khaki;
}
